import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { supabase } from '../supabase';
import { Helmet } from 'react-helmet';
import { Card, CardContent, Typography, Grid, Button, CardMedia, Chip, Container } from '@mui/material';
import { DirectionsCar as DirectionsCarIcon, Bed as BedIcon, Bathtub as BathtubIcon } from '@mui/icons-material';

const PropertyDetail = () => {
  const { id } = useParams();
  const [property, setProperty] = useState(null);
  const [recommendedProperties, setRecommendedProperties] = useState([]);

  useEffect(() => {
    const fetchProperty = async () => {
      const { data, error } = await supabase
        .from('properties')
        .select('*')
        .eq('id', id)
        .single();

      if (error) console.error('Error fetching property:', error);
      else setProperty(data);

      if (data) {
        const { data: recommendations, error: recError } = await supabase
          .from('properties')
          .select('*')
          .eq('city', data.city)
          .neq('id', id)
          .limit(6);

        if (recError) console.error('Error fetching recommendations:', recError);
        else setRecommendedProperties(recommendations);
      }
    };

    fetchProperty();
  }, [id]);

  if (!property) return <div>Loading...</div>;

  let pictures = [];
  if (property.pictures) {
    if (typeof property.pictures === 'string') {
      try {
        pictures = JSON.parse(property.pictures);
      } catch {
        pictures = [property.pictures];
      }
    } else if (Array.isArray(property.pictures)) {
      pictures = property.pictures;
    }
  }

  const formatPrice = (price) => {
    return price ? price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 'N/A';
  };

  const getLabelColor = () => {
    return property.type === 'For Sale' ? '#2e7d32' : '#1976d2';
  };

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "RealEstateListing",
    "url": window.location.href,
    "name": property.title,
    "description": property.content || "Property listing details",
    "image": pictures.length > 0 ? pictures[0] : '',
    "offers": {
      "@type": "Offer",
      "price": property.price || 0,
      "priceCurrency": "MYR"
    },
    "address": {
      "@type": "PostalAddress",
      "addressLocality": property.city,
      "addressRegion": property.region,
      "postalCode": property.postcode,
      "addressCountry": "MY"
    }
  };

  return (
    <div>
      <Helmet>
        <title>{`${property.title} | CARISINILA MALAYSIA`}</title>
        <meta name="description" content={`Explore details of ${property.title} in ${property.city}.`} />
        <meta name="keywords" content={`${property.city}, property for sale, ${property.rooms}, CARISINILA MALAYSIA`} />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>

      <Card style={{ maxWidth: '1200px', margin: 'auto', padding: '20px' }}>
        <CardContent>
          <Typography variant="h4" gutterBottom>{property.title}</Typography>

          <Chip 
            label={property.type} 
            style={{ backgroundColor: getLabelColor(), color: 'white', marginBottom: '10px', fontSize: '1rem', padding: '5px' }}
          />

          <Typography variant="h6" color="textSecondary">
            {property.city}, {property.region}
          </Typography>

          <Typography variant="h5" style={{ color: getLabelColor(), fontWeight: 'bold', marginTop: '10px' }}>
            Price: RM {formatPrice(property.price)}
          </Typography>

          <Grid container spacing={2} style={{ marginTop: '10px' }}>
            <Grid item xs={12} sm={4}>
              <Typography variant="body2">
                <BedIcon style={{ marginRight: '5px' }} />
                Bedrooms: {property.rooms}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="body2">
                <BathtubIcon style={{ marginRight: '5px' }} />
                Bathrooms: {property.bathrooms}
              </Typography>
            </Grid>
            {property.parking && (
              <Grid item xs={12} sm={4}>
                <Typography variant="body2">
                  <DirectionsCarIcon style={{ marginRight: '5px' }} />
                  Parking: {property.parking}
                </Typography>
              </Grid>
            )}
          </Grid>

          <Typography variant="body2" style={{ marginTop: '20px' }}>Address: {property.address}</Typography>
          <Typography variant="body2">Postcode: {property.postcode}</Typography>
          <Typography variant="body2" style={{ marginTop: '10px' }}>Description:</Typography>
          <Typography variant="body1" style={{ marginTop: '10px' }}>{property.content}</Typography>

          <Button
            variant="contained"
            color="primary"
            href={property.url}
            target="_blank"
            rel="noopener noreferrer"
            style={{ marginTop: '15px' }}
          >
            View Original Listing on Property Genie
          </Button>
        </CardContent>

        {/* Property Images */}
        <div style={{ marginTop: '20px' }}>
          {pictures.length > 0 ? (
            <Grid container spacing={2}>
              {pictures.map((picture, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <CardMedia
                    component="img"
                    image={typeof picture === 'string' ? picture : picture.picture_url}
                    alt={`Property image ${index + 1}`}
                    style={{ width: '100%', height: 'auto' }}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography>No images available</Typography>
          )}
        </div>
      </Card>

      {/* Recommended Properties Section */}
<Container maxWidth="lg" style={{ marginTop: '40px', padding: '20px', backgroundColor: '#f9f9f9', borderRadius: '8px' }}>
  <Typography variant="h5" gutterBottom style={{ textAlign: 'center', fontWeight: 'bold', color: '#1976d2' }}>
    Recommended Properties
  </Typography>

  {/* Optional Divider */}
  <div style={{ width: '50px', height: '4px', backgroundColor: '#1976d2', margin: '10px auto' }}></div>

  <Grid container spacing={2} justifyContent="center">
    {recommendedProperties.map((recProperty) => {
      let recPictures = [];
      if (recProperty.pictures) {
        try {
          recPictures = typeof recProperty.pictures === 'string'
            ? JSON.parse(recProperty.pictures)
            : Array.isArray(recProperty.pictures)
            ? recProperty.pictures
            : [recProperty.pictures];
        } catch {
          recPictures = [recProperty.pictures];
        }
      }

      const recLabelColor = recProperty.type === 'For Sale' ? '#2e7d32' : '#1976d2';

      return (
        <Grid item xs={12} sm={6} md={4} key={recProperty.id}>
          <Card style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            {/* Fixed height of 200px for image */}
            <CardMedia
              component="img"
              image={recPictures.length > 0 ? recPictures[0] : 'placeholder-image-url'}
              alt={recProperty.title}
              style={{ width: '100%', height: '200px', objectFit: 'cover' }}
            />
            <CardContent style={{ flexGrow: 1 }}>
              {/* Consistent layout: Title, Location, Details, Price */}
              <Typography variant="h6" style={{ height: '3em', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'normal' }}>
                {recProperty.title}
              </Typography>
              <Typography variant="body2" color="textSecondary" style={{ height: '2em', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'normal' }}>
                {recProperty.city}
              </Typography>

              {/* For Sale/For Rent Button */}
              <Chip 
                label={recProperty.type} 
                style={{ backgroundColor: recLabelColor, color: 'white', marginTop: '10px', fontSize: '0.9rem' }}
              />

              {/* Details: Bedrooms, Bathrooms, Parking */}
              <Grid container spacing={2} style={{ marginTop: '10px' }}>
                <Grid item>
                  <Typography variant="body2">
                    <BedIcon style={{ marginRight: '5px' }} />
                    {recProperty.rooms ? recProperty.rooms : 'N/A'} Bedrooms
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">
                    <BathtubIcon style={{ marginRight: '5px' }} />
                    {recProperty.bathrooms ? recProperty.bathrooms : 'N/A'} Bathrooms
                  </Typography>
                </Grid>
                {recProperty.parking && (
                  <Grid item>
                    <Typography variant="body2">
                      <DirectionsCarIcon style={{ marginRight: '5px' }} />
                      {recProperty.parking ? recProperty.parking : 'N/A'} Parking
                    </Typography>
                  </Grid>
                )}
              </Grid>

              <Typography variant="body2" style={{ color: recLabelColor, fontWeight: 'bold', marginTop: '10px' }}>
                Price: RM {formatPrice(recProperty.price)}
              </Typography>
            </CardContent>

            <div style={{ padding: '10px' }}>
              <Button
                component={Link}
                to={`/property/${recProperty.id}`}
                variant="contained"
                color="primary"
                fullWidth
              >
                View Details
              </Button>
            </div>
          </Card>
        </Grid>
      );
    })}
  </Grid>
</Container>
    </div>
  );
};

export default PropertyDetail;