import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Typography, Container, Box, Divider } from '@mui/material';

const Footer = () => {
  return (
    <footer style={{ backgroundColor: '#f4f4f4', padding: '40px 0', marginTop: '40px' }}>
      <Container maxWidth="lg">
        <Grid container spacing={4} justifyContent="space-between">
          
          {/* Company Info with Schema Markup for SEO */}
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="h6" gutterBottom>Carisinila Corporation</Typography>
            <Typography variant="body2" paragraph>
              Cerulean Tower, 26-1 Sakuragaokacho, Shibuya-ku, Tokyo 150-8512, Japan
            </Typography>
            <Typography variant="body2" gutterBottom>
              Email: 
              <a href="mailto:contact@carisinila.com" style={{ color: '#3f51b5', textDecoration: 'none', marginLeft: '5px' }}>
                contact@carisinila.com
              </a>
            </Typography>
            <Divider style={{ margin: '20px 0' }} />
            <Typography variant="body2" color="textSecondary">
              Follow us on: 
              <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" style={{ marginLeft: '10px', color: '#3f51b5' }}>
                Facebook
              </a> | 
              <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer" style={{ marginLeft: '10px', color: '#3f51b5' }}>
                Twitter
              </a>
            </Typography>
          </Grid>

          {/* Useful Links with Internal Link Structure */}
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="h6" gutterBottom>Useful Links</Typography>
            <nav>
              <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                <li>
                  <a href="https://www.propertygenie.com.my/insider-guide" target="_blank" rel="noopener noreferrer" style={{ color: '#3f51b5', textDecoration: 'none' }}>
                    Malaysia Real Estate News
                  </a>
                </li>
                <li>
                  <a href="https://www.propertygenie.com.my/transaction-and-statistics" target="_blank" rel="noopener noreferrer" style={{ color: '#3f51b5', textDecoration: 'none' }}>
                    Malaysia Property Transaction History
                  </a>
                </li>
                <li>
                  <Link to="/properties" style={{ color: '#3f51b5', textDecoration: 'none' }}>
                    Malaysia Property For Sale
                  </Link>
                </li>
                <li>
                  <Link to="/about-us" style={{ color: '#3f51b5', textDecoration: 'none' }}>
                    About Us
                  </Link>
                </li>
                <li>
                  <Link to="/contact" style={{ color: '#3f51b5', textDecoration: 'none' }}>
                    Contact Us
                  </Link>
                </li>
              </ul>
            </nav>
          </Grid>

          {/* Popular Areas with Geographically Relevant Keywords */}
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="h6" gutterBottom>Popular Areas</Typography>
            <nav>
              <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                <li>
                  <a href="https://www.propertygenie.com.my/property-for-sale/kuala-lumpur/desa-parkcity" target="_blank" rel="noopener noreferrer" style={{ color: '#3f51b5', textDecoration: 'none' }}>
                    Desa Park City Properties for Sale
                  </a>
                </li>
                <li>
                  <a href="https://www.propertygenie.com.my/property-for-sale/selangor/damansara" target="_blank" rel="noopener noreferrer" style={{ color: '#3f51b5', textDecoration: 'none' }}>
                    Damansara Properties for Sale
                  </a>
                </li>
                <li>
                  <a href="https://www.propertygenie.com.my/property-for-sale/penang/georgetown" target="_blank" rel="noopener noreferrer" style={{ color: '#3f51b5', textDecoration: 'none' }}>
                    Georgetown, Penang Properties for Sale
                  </a>
                </li>
              </ul>
            </nav>
          </Grid>
        </Grid>

        {/* Footer Bottom */}
        <Box textAlign="center" marginTop="40px" paddingTop="20px" borderTop="1px solid #ddd">
          <Typography variant="body2" color="textSecondary">
            &copy; {new Date().getFullYear()} Carisinila Corporation. All rights reserved.
          </Typography>
        </Box>

        {/* Structured Data for SEO */}
        <script type="application/ld+json">
  {JSON.stringify({
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Carisinila Corporation",
    "url": "https://www.carisinila.com",
    "logo": "https://www.carisinila.com/logo.png",
    "sameAs": [
      "https://www.facebook.com/CarisinilaCorp",
      "https://twitter.com/CarisinilaCorp"
    ],
    "contactPoint": {
      "@type": "ContactPoint",
      "email": "contact@carisinila.com",
      "contactType": "Customer Service",
      "areaServed": "MY",
      "availableLanguage": ["English", "Malay"]
    },
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "Cerulean Tower, 26-1 Sakuragaokacho",
      "addressLocality": "Shibuya-ku",
      "addressRegion": "Tokyo",
      "postalCode": "150-8512",
      "addressCountry": "JP"
    }
  })}
</script>
      </Container>
    </footer>
  );
};

export default Footer;